import React, { useContext } from "react";
import Layout from "../components/layout/Layout";
import TextCard from "../components/textCard/TextCard";
import Map from "../components/map/Map";
import Head from "../components/head/Head";
import { HomeSectionContext } from "../context/HomeSectionContext";
import { OpeningHours } from "../components/openingHours/OpeningHours";
import { mapLocation } from "../constants/location";
import Button from "../components/button/Button";
import menuCard from "../assets/pdf/speisekarte.pdf";
import ImageBackground from "../components/imageBackground/ImageBackground";
import IconPlusText from "../components/iconPlusText/IconPlusText";
import { ContactLinks } from "../constants/contactLinks";
import NoticeBanner from "../components/noticeBanner/NoticeBanner";
import { useAnnouncementQuery } from "../context/useAnnouncementQuery";

const IndexPage = () => {
  const { homeInfo, navbarLinks } = useContext(HomeSectionContext);
  const { active, title, html } = useAnnouncementQuery();

  let intro = homeInfo[0];
  let menu = homeInfo[1];
  let callUs = homeInfo[2];
  let aboutUs = homeInfo[3];
  let openingHours = homeInfo[4];
  let location = homeInfo[5];

  const contactInfo = ContactLinks();

  const noSidebar = true;

  return (
    <div className="page__body">
      <Layout navbarLinks={navbarLinks} sectionLink={true}>
        <Head
          title="China-Thai Schnellrestaurant in Bad Neuenahr-Ahrweiler"
          titleSuffix={false}
          robots="index,follow"
        />
        <main>
          <section id={intro.url} className="page__element">
            <TextCard
              title={intro.title}
              dangerouslySetInnerHTML={intro.html}
              pageTitle
            />
          </section>
          <ImageBackground
            img={menu.image}
            imgAlt={`${menu.link}-bild`}
            renderContent={() => (
              <section id={menu.url} className="page__element">
                <TextCard
                  title={menu.title}
                  dangerouslySetInnerHTML={menu.html}
                />
                <a
                  className="no-text-decoration"
                  href={menuCard}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="box-pad-s">
                    <Button btnText="Speisekarte & Tagesmenü" />
                  </div>
                </a>
              </section>
            )}
          />
          <section id={callUs.url} className="page__element">
            <TextCard
              title={callUs.title}
              dangerouslySetInnerHTML={callUs.html}
            />
            <div className="box-pad-s">
              {contactInfo.map((item, index) => {
                let Icon = item.icon;
                return (
                  <IconPlusText key={index} text={item.text} link={item.url}>
                    <Icon />
                  </IconPlusText>
                );
              })}
            </div>
          </section>
          <ImageBackground
            img={aboutUs.image}
            imgAlt={`${aboutUs.link}-img`}
            renderContent={() => (
              <section id={aboutUs.url} className="page__element">
                <TextCard
                  title={aboutUs.title}
                  dangerouslySetInnerHTML={aboutUs.html}
                />
              </section>
            )}
          />
          <section id={openingHours.url} className="page__element">
            <TextCard
              title={openingHours.title}
              dangerouslySetInnerHTML={openingHours.html}
            />
            <OpeningHours />
          </section>
          <section
            id={location.url}
            className="page__element no-bottom-padding"
          >
            <TextCard
              title={location.title}
              dangerouslySetInnerHTML={location.html}
            />
            <Map location={mapLocation} fullWidth />
          </section>
        </main>
      </Layout>
      {/* <NoticeBanner active={active} title={title} dangerouslySetInnerHTML={html} date={[2024, 6, 31]} /> */}
      <NoticeBanner
        active={active}
        title={title}
        dangerouslySetInnerHTML={html}
        date={[2025, 0, 2]}
      />
    </div>
  );
};

export default IndexPage;
